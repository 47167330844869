import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <h1>Cosmic Hellenism</h1>
      <p>
        An alternate world history in where the civilization of Ancient Greece never died but thrived into the distant future
      </p>
    </div>
  );
}

export default App;
